import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
    BulkAction,
    DeletionResult,
    isMultiChannel,
    ItemOf,
    ModalService,
    NotificationService,
    Permission,
} from '@vendure/admin-ui/core';
import { CrudPermissionDefinition } from "@vendure/core"
import { unique } from '@vendure/common/lib/unique';
import { EMPTY } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetCouponListQuery } from '../../generated-types';
//import { BulkAddFacetValuesDialogComponent } from '@vendure/admin-ui/catalog/components/bulk-add-facet-values-dialog/bulk-add-facet-values-dialog.component'
//import { AssignProductsToChannelDialogComponent } from '@vendure/admin-ui/catalog/components/assign-products-to-channel-dialog/assign-products-to-channel-dialog.component';

import { AssignCouponsToChannelDialogComponent } from '../assign-coupons-to-channel-dialog/assign-coupons-to-channel-dialog.component'
import { CouponListComponent } from './coupon-list.component'
import { CustomDataService } from '../../data/providers/custom-data.service';
import { createBulkRemoveFromChannelAction } from './bulk-action-utils'

export const deleteCouponsBulkAction: BulkAction<
    ItemOf<GetCouponListQuery, 'coupons'>,
    CouponListComponent
> = {
    location: 'coupon-list',
    label: _('common.delete'),
    icon: 'trash',
    iconClass: 'is-danger',
    requiresPermission: userPermissions => userPermissions.includes('UpdateCoupon'),
    onClick: ({ injector, selection, hostComponent, clearSelection }) => {
        const modalService = injector.get(ModalService);
        const customDataService = injector.get(CustomDataService);
        const notificationService = injector.get(NotificationService);
        modalService
            .dialog({
                title: `Delete ${selection.length} coupons`,
                buttons: [
                    { type: 'secondary', label: _('common.cancel') },
                    { type: 'danger', label: _('common.delete'), returnValue: true },
                ],
            })
            .pipe(
                switchMap(response => 
                    response ? customDataService.coupon.deleteCoupons(unique(selection.map(p => p.id))) : EMPTY,
                ),
            )
            .subscribe(result => {
                let deleted = 0;
                const errors: string[] = [];
                for (const item of result.deleteCoupons) {
                    if (item.result === DeletionResult.DELETED) {
                        deleted++;
                    } else if (item.message) {
                        errors.push(item.message);
                    }
                }
                if (0 < deleted) {
                    notificationService.success(_('catalog.notify-bulk-delete-coupons-success'), {
                        count: deleted,
                    });
                }
                if (0 < errors.length) {
                    notificationService.error(errors.join('\n'));
                }
                hostComponent.refresh();
                clearSelection();
            });
    },
};

export const assignCouponsToChannelBulkAction: BulkAction<
    ItemOf<GetCouponListQuery, 'coupons'>,
    CouponListComponent
> = {
    location: 'coupon-list',
    label: _('catalog.assign-to-channel'),
    icon: 'layers',
    requiresPermission: userPermissions => userPermissions.includes('UpdateCoupon'),
    //isVisible: ({ injector }) => isMultiChannel(injector.get(CustomDataService)),
    onClick: ({ injector, selection, clearSelection }) => {
        const modalService = injector.get(ModalService);
        modalService
            .fromComponent(AssignCouponsToChannelDialogComponent, {
                size: 'lg',
                locals: {
                    couponIds: unique(selection.map(p => p.id)),
                    currentChannelIds: [],
                },
            })
            .subscribe(result => {
                if (result) {
                    clearSelection();
                }
            });
    },
};

export const removeCouponsFromChannelBulkAction = createBulkRemoveFromChannelAction<
    ItemOf<GetCouponListQuery, 'coupons'>
>({
    location: 'coupon-list',
    requiresPermission: userPermissions => userPermissions.includes('UpdateCoupon'),
    getItemName: item => item.name,
    bulkRemoveFromChannel: (customDataService, dataService, couponIds, channelId) =>
      customDataService.coupon.removeCouponsFromChannel({
                channelId: channelId,
                couponIds,
            })
            .pipe(map(res => res.removeCouponsFromChannel)),
});

