import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
    DataService,
    Dialog,
    GetChannelsQuery,
    ItemOf,
    NotificationService,
    ProductVariantFragment,
} from '@vendure/admin-ui/core';
import { CustomDataService } from '../../data/providers/custom-data.service'
import { combineLatest, from, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

type Channel = ItemOf<GetChannelsQuery, 'channels'>;

@Component({
    selector: 'vdr-assign-coupons-to-channel-dialog',
    templateUrl: './assign-coupons-to-channel-dialog.component.html',
    //styleUrls: ['./assign-coupons-to-channel-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignCouponsToChannelDialogComponent implements OnInit, Dialog<any> {
    selectedChannel: Channel | null | undefined;
    currentChannel: Channel;
    availableChannels: Channel[];
    resolveWith: (result?: any) => void;
    selectedChannelIdControl = new UntypedFormControl();

    // assigned by ModalService.fromComponent() call
    couponIds: string[];
    currentChannelIds: string[];

    constructor(private dataService: DataService, private customDataService: CustomDataService, private notificationService: NotificationService) {}

    ngOnInit() {
      const activeChannelId$ = this.dataService.client
          .userStatus()
          .mapSingle(({ userStatus }) => userStatus.activeChannelId);
      const allChannels$ = this.dataService.settings.getChannels().mapSingle(data => data.channels);

      combineLatest(activeChannelId$, allChannels$).subscribe(([activeChannelId, channels]) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.currentChannel = channels.items.find(c => c.id === activeChannelId)!;
          this.availableChannels = channels.items;
        });

        this.selectedChannelIdControl.valueChanges.subscribe(ids => {
            this.selectChannel(ids);
        });
    }

    selectChannel(channelIds: string[]) {
      this.selectedChannel = this.availableChannels.find(c => c.id === channelIds[0]);
    }

    assign() {
      const selectedChannel = this.selectedChannel;
      if (selectedChannel) {
        this.customDataService.coupon
        .assignCouponsToChannel({
            channelId: selectedChannel.id,
            couponIds: this.couponIds,
        })
        .subscribe(() => {
            this.notificationService.success('Successfully assign coupons to channel', {
                channel: selectedChannel.code,
                count: this.couponIds.length,
            });
            this.resolveWith(true);
        });
      }
    }

    cancel() {
      this.resolveWith();
    }
}