import * as Codegen from '../../generated-types'
import { BaseDataService } from '@vendure/admin-ui/core'
import { pick } from '@vendure/common/lib/pick';
import {
  CREATE_COUPON,
  UPDATE_COUPON,
  DELETE_COUPON,
  DELETE_COUPONS,
  ASSIGN_COUPONS_TO_CHANNEL,
  REMOVE_COUPONS_FROM_CHANNEL
} from '../definitions/coupon-definitions'

export class CouponDataService {
  constructor(private baseDataService: BaseDataService) {}

  createCoupon(input: Codegen.CreateCouponInput) {
    return this.baseDataService.mutate<
        Codegen.CreateCouponMutation,
        Codegen.CreateCouponMutationVariables
    >(CREATE_COUPON, {
      input,
    });
  }

  updateCoupon(input: Codegen.UpdateCouponInput) {
    return this.baseDataService.mutate<
        Codegen.UpdateCouponMutation,
        Codegen.UpdateCouponMutationVariables
    >(UPDATE_COUPON, {
      input
    });
}

  deleteCoupon(id: string) {
    return this.baseDataService.mutate<
        Codegen.DeleteCouponMutation,
        Codegen.DeleteCouponMutationVariables
    >(DELETE_COUPON, {
        id,
    });
  }

  deleteCoupons(ids: string[]) {
    return this.baseDataService.mutate<
        Codegen.DeleteCouponsMutation,
        Codegen.DeleteCouponsMutationVariables
    >(DELETE_COUPONS, {
        ids,
    });
  }

  assignCouponsToChannel(input: Codegen.AssignCouponsToChannelInput) {
    return this.baseDataService.mutate<
        Codegen.AssignCouponsToChannelMutation,
        Codegen.AssignCouponsToChannelMutationVariables
    >(ASSIGN_COUPONS_TO_CHANNEL, {
        input,
    });
  }

  removeCouponsFromChannel(input: Codegen.RemoveCouponsFromChannelInput) {
      return this.baseDataService.mutate<
          Codegen.RemoveCouponsFromChannelMutation,
          Codegen.RemoveCouponsFromChannelMutationVariables
      >(REMOVE_COUPONS_FROM_CHANNEL, {
          input,
      });
  }

}