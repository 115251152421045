import { gql } from 'apollo-angular';

export const ASSET_FRAGMENT = gql`
    fragment Asset on Asset {
    	id
        createdAt
        updatedAt
        fileSize
        mimeType
        width
        height
        source
        preview
        focalPoint {
            x
            y
        }
  	}
`;

export const COUPON_DETAIL_FRAGMENT = gql`
    fragment CouponDetail on Coupon {
        id
        createdAt
        updatedAt
        deletedAt
        startsAt
        endsAt
        couponCode
        name
        slug
        featuredAsset {
            ...Asset
        }
        assets {
            ...Asset
        }
        translations {
            id
            languageCode
            name
            slug
            description
            tnc
        }
        facetValues {
            id
            code
            name
            facet {
                id
                name
            }
        }
        channels {
            id
            code
        }
    }
    ${ASSET_FRAGMENT}
`;

export const GET_COUPON = gql`
    query GetCoupon($id: ID!) {
        coupon(id: $id) {
            ...CouponDetail
        }
    }
    ${COUPON_DETAIL_FRAGMENT}
`;

export const CREATE_COUPON = gql`
    mutation CreateCoupon($input: CreateCouponInput!) {
        createCoupon(input: $input) {
            ...CouponDetail
        }
    }
    ${COUPON_DETAIL_FRAGMENT}
`;

export const UPDATE_COUPON = gql`
    mutation UpdateCoupon($input: UpdateCouponInput!) {
        updateCoupon(input: $input) {
            ...CouponDetail
        }
    }
    ${COUPON_DETAIL_FRAGMENT}
`;

export const DELETE_COUPON = gql`
    mutation DeleteCoupon($id: ID!) {
        deleteCoupon(id: $id) {
            result
            message
        }
    }
`;

export const DELETE_COUPONS = gql`
    mutation DeleteCoupons($ids: [ID!]!) {
      deleteCoupons(ids: $ids) {
            result
            message
        }
    }
`;

export const ASSIGN_COUPONS_TO_CHANNEL = gql`
    mutation AssignCouponsToChannel($input: AssignCouponsToChannelInput!) {
        assignCouponsToChannel(input: $input) {
            id
            channels {
                id
                code
            }
        }
    }
`;

export const REMOVE_COUPONS_FROM_CHANNEL = gql`
    mutation RemoveCouponsFromChannel($input: RemoveCouponsFromChannelInput!) {
        removeCouponsFromChannel(input: $input) {
            id
            channels {
                id
                code
            }
        }
    }
`;