import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  BulkAction,
  DataService,
  ModalService,
  NotificationService,
  Permission,
  BaseListComponent
} from '@vendure/admin-ui/core';
import { EMPTY, from, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomDataService } from '../../data/providers/custom-data.service';

/**
 * @description
 * Resolves to an object containing the Channel code of the given channelId, or if no channelId
 * is supplied, the code of the activeChannel.
 */
export function getChannelCodeFromUserStatus(dataService: DataService, channelId?: string) {
  return lastValueFrom(
      dataService.client.userStatus().mapSingle(({ userStatus }) => {
          const channelCode =
              userStatus.channels.find(c => c.id === (channelId ?? userStatus.activeChannelId))?.code ??
              'undefined';
          return { channelCode };
      }),
  );
}

export type CreateBulkRemoveFromChannelActionConfig<ItemType, RemoveResult = Partial<ItemType>> = Pick<
    BulkAction,
    'location' | 'requiresPermission'
> & {
    getItemName: (item: ItemType) => string;
    bulkRemoveFromChannel: (
        customDataService: CustomDataService,
        dataService: DataService,
        ids: string[],
        channelId: string,
        retrying: boolean,
    ) => Observable<RemoveResult[]>;
    /**
     * An optional test of whether the removal succeeded for the given item. If this
     * function returns a string, that is taken to be an error message which will be
     * displayed to the user.
     */
    isErrorResult?: (result: RemoveResult) => string | undefined;
};

export function createBulkRemoveFromChannelAction<ItemType, ResultType = Partial<ItemType>>(
  config: CreateBulkRemoveFromChannelActionConfig<ItemType, ResultType>,
) {
  const bulkRemoveFromChannelAction: BulkAction<any, BaseListComponent<any, any>> = {
      location: config.location,
      label: _('common.remove-from-channel'),
      icon: 'layers',
      iconClass: 'is-warning',
      requiresPermission: config.requiresPermission,
      onClick: ({ injector, selection, hostComponent, clearSelection }) => {
          const modalService = injector.get(ModalService);
          const dataService = injector.get(DataService);
          const customDataService = injector.get(CustomDataService);
          const notificationService = injector.get(NotificationService);
          const activeChannelId$ = dataService.client
              .userStatus()
              .mapSingle(({ userStatus }) => userStatus.activeChannelId);

          function showModalAndDelete(items: ItemType[], message?: string) {
              const itemNames = items
                  .slice(0, 5)
                  .map(c => config.getItemName(c))
                  .join(', ');
              const nMore = items.length > 5 ? items.length - 5 : 0;
              return modalService
                  .dialog({
                      title: _('common.confirm-bulk-remove-from-channel'),
                      body: message ? message : nMore ? _('common.list-items-and-n-more') : itemNames,
                      translationVars: {
                          count: selection.length,
                          items: itemNames,
                          nMore,
                      },
                      size: message ? 'lg' : 'md',
                      buttons: [
                          { type: 'secondary', label: _('common.cancel') },
                          {
                              type: 'danger',
                              label: message ? _('common.force-remove') : _('common.remove'),
                              returnValue: true,
                          },
                      ],
                  })
                  .pipe(
                      switchMap(res =>
                          res
                              ? activeChannelId$.pipe(
                                    switchMap(activeChannelId =>
                                        activeChannelId
                                            ? config.bulkRemoveFromChannel(
                                                  customDataService,
                                                  dataService,
                                                  selection.map(c => c.id),
                                                  activeChannelId,
                                                  message != null,
                                              )
                                            : EMPTY,
                                    ),
                                )
                              : EMPTY,
                      ),
                  );
          }

          showModalAndDelete(selection)
              .pipe(
                  switchMap(result => {
                      let removedCount = selection.length;
                      const errors: string[] = [];
                      const errorIds: string[] = [];
                      let i = 0;
                      for (const item of result) {
                          const errorMessage = config.isErrorResult
                              ? config.isErrorResult(item)
                              : undefined;
                          if (errorMessage) {
                              errors.push(errorMessage);
                              errorIds.push(selection[i]?.id);
                              removedCount--;
                          }
                          i++;
                      }
                      if (0 < errorIds.length) {
                          const errorSelection = selection.filter(s => errorIds.includes(s.id));
                          return showModalAndDelete(errorSelection, errors.join('\n')).pipe(
                              map(result2 => {
                                  const notRemovedCount = result2.filter(r => {
                                      const secondTryErrorMessage = config.isErrorResult
                                          ? config.isErrorResult(r)
                                          : undefined;
                                      return typeof secondTryErrorMessage === 'string';
                                  }).length;
                                  return selection.length - notRemovedCount;
                              }),
                          );
                      } else {
                          return of(removedCount);
                      }
                  }),
                  switchMap(removedCount =>
                      removedCount
                          ? getChannelCodeFromUserStatus(dataService).then(({ channelCode }) => ({
                                channelCode,
                                removedCount,
                            }))
                          : EMPTY,
                  ),
              )
              .subscribe(({ removedCount, channelCode }) => {
                  if (removedCount) {
                      hostComponent.refresh();
                      clearSelection();
                      notificationService.success(
                          _('common.notify-remove-from-channel-success-with-count'),
                          {
                              count: removedCount,
                              channelCode,
                          },
                      );
                  }
              });
      },
  };
  return bulkRemoveFromChannelAction;
}