<ng-template vdrDialogTitle>
  <ng-template #couponModeTitle>{{ 'Assign Coupons to Channel' }}</ng-template>
</ng-template>

<div class="flex">
  <clr-input-container>
      <label>{{ 'common.channel' | translate }}</label>
      <vdr-channel-assignment-control
          clrInput
          [multiple]="false"
          [includeDefaultChannel]="false"
          [disableChannelIds]="currentChannelIds"
          [formControl]="selectedChannelIdControl"
      ></vdr-channel-assignment-control>
  </clr-input-container>
  <div class="flex-spacer"></div>
  
</div>

<ng-template vdrDialogButtons>
  <button type="button" class="btn" (click)="cancel()">{{ 'common.cancel' | translate }}</button>
  <button type="submit" (click)="assign()" [disabled]="!selectedChannel" class="btn btn-primary">
      <ng-template [ngIf]="selectedChannel" [ngIfElse]="noSelection">
          {{ 'catalog.assign-to-named-channel' | translate: { channelCode: selectedChannel?.code } }}
      </ng-template>
      <ng-template #noSelection>
          {{ 'catalog.no-channel-selected' | translate }}
      </ng-template>
  </button>
</ng-template>