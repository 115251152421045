import { Injectable } from '@angular/core';
import { MutationUpdaterFn, WatchQueryFetchPolicy } from '@apollo/client/core';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode } from 'graphql';
import { Observable } from 'rxjs';

import { QueryResult, BaseDataService } from '@vendure/admin-ui/core'
import { CouponDataService } from './coupon-data.service';

@Injectable()
export class CustomDataService {

  /** @internal */ coupon: CouponDataService;

  constructor(private baseDataService: BaseDataService) {
    this.coupon = new CouponDataService(baseDataService);
  }

  /**
     * @description
     * Perform a GraphQL query. Returns a {@link QueryResult} which allows further control over
     * they type of result returned, e.g. stream of values, single value etc.
     *
     * @example
     * ```TypeScript
     * const result$ = this.dataService.query(gql`
     *   query MyQuery($id: ID!) {
     *     product(id: $id) {
     *       id
     *       name
     *       slug
     *     }
     *   },
     *   { id: 123 },
     * ).mapSingle(data => data.product);
     * ```
     */
  query<T, V extends Record<string, any> = Record<string, any>>(
    query: DocumentNode | TypedDocumentNode<T, V>,
    variables?: V,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  ): QueryResult<T, V> {
      return this.baseDataService.query(query, variables, fetchPolicy);
  }

  /**
   * @description
   * Perform a GraphQL mutation.
   *
   * @example
   * ```TypeScript
   * const result$ = this.dataService.mutate(gql`
   *   mutation MyMutation($Codegen.UpdateEntityInput!) {
   *     updateEntity(input: $input) {
   *       id
   *       name
   *     }
   *   },
   *   { Codegen.updateEntityInput },
   * );
   * ```
   */
  mutate<T, V extends Record<string, any> = Record<string, any>>(
      mutation: DocumentNode | TypedDocumentNode<T, V>,
      variables?: V,
      update?: MutationUpdaterFn<T>,
  ): Observable<T> {
      return this.baseDataService.mutate(mutation, variables, update);
  }
}