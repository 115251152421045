import { NgModule } from '@angular/core';
import { SharedModule, addNavMenuItem, registerBulkAction, DeletionResult } from '@vendure/admin-ui/core';
import { CustomDataService } from './data/providers/custom-data.service';
import { deleteCouponsBulkAction, removeCouponsFromChannelBulkAction, assignCouponsToChannelBulkAction } from './components/coupon-list/coupon-list-bulk-actions.component'

@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuItem({
      id: 'coupons',
      label: 'Coupons',
      routerLink: ['/extensions/coupons'],
      icon: 'details',
    },
    'catalog'),
    CustomDataService,
    registerBulkAction(deleteCouponsBulkAction),
    registerBulkAction(assignCouponsToChannelBulkAction),
    registerBulkAction(removeCouponsFromChannelBulkAction),
  ]
})
export class CouponsSharedModule {}